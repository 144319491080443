import React from "react";
import { NewCookingEventProvider } from "../contexts";
import { Layout, SEO, EventsList } from "../components";

const EventsPage = () => {
  return (
    <NewCookingEventProvider>
      <Layout>
        <SEO title="Events" />

        <EventsList />
      </Layout>
    </NewCookingEventProvider>
  );
};

export default EventsPage;
